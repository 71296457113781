import React, { Suspense, useEffect, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate  } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Loading } from "./loading";
import Loader from "./login/loader";
import LazyImage from "./LazyImage";
import jewel from "./assets/images/chatbot-ask-logo-icon.svg";
import Variables from './core/utils/variables';

const queryClient = new QueryClient();

const Pricing = lazy(() => import("./pricing"));
const PricingStart = lazy(() => import("./pricing/index_start"));
const Register = lazy(() => import("./register"));
const RegisterStart = lazy(() => import("./register/start"));
// const LoginStart = lazy(() => import("./login/start"));
const Login = lazy(() => import("./login"));
const Forgot = lazy(() => import("./forgot"));
// const Reset = lazy(() => import("./resetpass"));
// const Landing = lazy(() => import("./lp/landing-start"));
const Landing = lazy(() => import("./lp/landing"));
// const Pay = lazy(() => import("./pay/index_start"));
// const PayV2 = lazy(() => import("./pay/index_02_start"));
// const PayV3 = lazy(() => import("./pay/index_03_start"));

const Pay = lazy(() => import("./pay"));
const PayV2 = lazy(() => import("./pay/index_02"));
const PayV3 = lazy(() => import("./pay/index_03"));
// const Verify = lazy(() => import("./verify/index"));
// const EmailVerification = lazy(() => import("./verify/index_01"));

const Onboarding = lazy(()=> import("./onboarding/onboarding"))

function App() {
  useEffect(() => {
    import("./tailwind.scss");
    import("./index.css");
  }, []);

  return (
    <Router>
      <div>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<Loading />}>
            <Routes>
            {/* <Route path="/landing" element={<Navigate to="/start" />} />  */}
              <Route path="/landing" element={<Landing />} />
              <Route path="/register" element={<RegisterStart />} />
              <Route path="/pricing" element={<Navigate to="/plan" />} />
              {/* <Route path="/register" element={<Navigate to="/landing/register" />} /> */}
              {/* <Route path="/pay/1LCXJMZNX6" element={<Pay />} />
              <Route path="/pay/VS6lni4hKx" element={<PayV2 />} />
              <Route path="/pay/uHTinVqsUl" element={<PayV3 />} /> */}
              <Route path="/pay/1LCXJMZNX6" element={<Navigate to="/landing/pay/1LCXJMZNX6" />} />
              <Route path="/pay/VS6lni4hKx" element={<Navigate to="/landing/pay/VS6lni4hKx" />} />
              <Route path="/pay/uHTinVqsUl" element={<Navigate to="/landing/pay/uHTinVqsUl" />} />
              {/* <Route path="/login"  element={<LoginStart />} /> */}
              <Route path="/login" element={<Navigate to="/landing/login" />} />
              {/* <Route path="/forgot" element={<Navigate to="/landing/forgot-password" />} /> */}

              <Route path="/landing/register" element={<Register />} />
              <Route path="/landing/login" element={<Login />} />
              <Route path="/landing/plan" element={<Pricing />} />
              <Route path="/landing/forgot-password" element={<Forgot />} />
              <Route path="/plan" element={<PricingStart />} />
              {/* <Route path="/landing/verify" element={<Verify />} /> */}
              {/* <Route path="/landing/verifying-email" element={<EmailVerification />} /> */}
              <Route path="/landing/pay" element={<Navigate to="/pay" />} />
              <Route path="/landing/pay/1LCXJMZNX6" element={<Pay />} />
              <Route path="/landing/pay/VS6lni4hKx" element={<PayV2 />} />
              <Route path="/landing/pay/uHTinVqsUl" element={<PayV3 />} />
              {/* {/* <Route path="/resetpass" element={<Reset />} /> */}
              <Route path="/onboarding" element={<Onboarding />} />
            </Routes>
          </Suspense>
        </QueryClientProvider>
        <Variables />
        <div className="loader-container">
          <div className="overlay"></div>
          <div className="lds-ai">
            <div className="">
              <LazyImage
                src={jewel}
                alt="Logo"
                className="ml-auto mr-auto block h-auto w-[50px] mb-[70px]"
              />
              <Loader alt="loader" width="50" height="50" className="text-center mx-auto mb-12" />
              {/* <img src={loader} alt="loader" width="40" height="40" className="text-center mx-auto mb-12"/> */}
              <div className='text-xl font-bold text-center text-white'><span className="text-[#177755]">Processing </span> your subscription</div>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}
export default App;
