import React from "react";

const Loader = ({ className, action }) => {
  return (
    <div
      className={`m-auto flex items-center justify-center ${action ? 'p-0' : 'p-12'} ${className}`}
    >
      <span className="loader relative inline-block h-12 w-12 animate-[mltShdSpin_1.7s_infinite_ease,_round_1.7s_infinite_ease] rounded-full text-5xl text-[#177755] [text-indent:_-9999em;] [transform:_translateZ(0)]" />
    </div>
  );
};

export default Loader;
