import React, { Suspense } from 'react';

const LazyImage = ({ src, alt, ...props }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <img src={src} loading="lazy" alt={alt} {...props} />
    </Suspense>
  );
};

export default LazyImage;
